'use client'
import React from 'react'
import styled from 'styled-components'
import BreacrumbComposition from '../../compositions/breadcrumbs'
import WidthContainer from '../../elements/widthContainer'

const BreadcrumbWrapper = styled.div`
  margin-bottom: ${(props) => `${props.spacing}px`};
  @media screen and (max-width: 1060px) {
    margin: 0;
  }
`

const BreadCrumbs = ({ breadcrumbs, spacing = 60, widthVariant = 'semi' }) => {
  return (
    <WidthContainer variant={widthVariant}>
      <BreadcrumbWrapper spacing={spacing}>
        <BreacrumbComposition variant="header" items={breadcrumbs} />
      </BreadcrumbWrapper>
    </WidthContainer>
  )
}

export default BreadCrumbs
