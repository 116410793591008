"use client"
import React from "react";

import WidthContainer from "@/core/elements/widthContainer";
import { Wrapper } from "@/core/sections/filterContentList/views/styles";
import FilterSidebar from "@/core/sections/filterContentList/filterSidebar";
import { useFilterState } from "@/core/filterContext";

const SidebarLayout = ({
    children,
    get_filters: mainFilters,
    quick_filters,
    get_global_attributes: attributeFilters,
    isLocation=false,
    additionalFilters=[],
}) => {
    const filterSubList = (
        quick_filters && quick_filters[0] && quick_filters[0].value
    ) || {}
    const {
        filterState,
        clubs,
        locations,
        handleFiltersChange,
        handleFilters,
        handleAttributeChange,
        handleAdditionalChange,
        handleDistancePostalcodeChange,
        removeFilters,
        handleCategoryChange,
        filterOpenMobile,
        setMobileOpen,
    } = useFilterState()

    const {
        category,
        filters,
        attributes: selectedAttributes,
        additionals: selectedAdditionals,
        distance,
        postalCode,
    } = filterState

    const data = (isLocation ? locations : clubs)?.state || {}

    const distancePostalcode =
        distance && postalCode ? { distance, postalCode } : null

    return (
        <WidthContainer>
            <Wrapper>
                <div className={`${filterOpenMobile ? 'open' : ''} filterContentSidebar`}>
                    <FilterSidebar
                        filterSubList={filterSubList}
                        resultsPerCategory={data?.categories_count}
                        resultsPerSport={data?.sports_count}
                        mainFilters={mainFilters}
                        attributeFilters={attributeFilters}
                        additionalFilters={additionalFilters}
                        filters={filters}
                        category={category}
                        distancePostalcode={distancePostalcode}
                        selectedAttributes={selectedAttributes}
                        selectedAdditionals={selectedAdditionals}
                        className={`${filterOpenMobile ? 'open' : ''}`}
                        setMobileOpen={setMobileOpen}
                        handleFilters={handleFilters}
                        handleFiltersChange={handleFiltersChange}
                        handleAttributeChange={handleAttributeChange}
                        handleAdditionalChange={handleAdditionalChange}
                        handleCategoryChange={handleCategoryChange}
                        handleDistancePostalcodeChange={handleDistancePostalcodeChange}
                        removeFilters={removeFilters}
                        isLocation={isLocation}
                    />
                </div>
                { children }
            </Wrapper>
        </WidthContainer>
    )
}

export default SidebarLayout
